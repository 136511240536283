import React from 'react'
import styled from 'styled-components'
import Carousel from 'nuka-carousel'
import './carousel.css'
import { ImageLink, CinemaId } from '../model/model';
import { SmartLink } from './utils';
import RImage from './image';

const CarouselControlWrapper = styled.div.attrs({
  isLeft: props => props.isLeft
})`
  display: none;
  @media(min-width: 700px) {
    display: flex;
    width: 40px;
    transform: rotate(180deg);
    justify-content: center;
    user-select: none;
    filter: drop-shadow( 0 0 5px #000 );
    position: relative;
    left: ${props => props.isLeft
      ? "-50px;"
      : "50px;"}
    transform: ${props => props.isLeft
      ? "rotate(180deg);"
      : "rotate(0deg);"}
    svg path {
      transition: 0.25s stroke;
      stroke: #FFFFFF;
    }
    &:hover svg path {
      stroke: ${props => props.theme.colors.highlight};
    }
  }
}
`
// const CarouselControl = ({isLeft, onClick}) => (
//   <CarouselControlWrapper isLeft={isLeft} onClick={onClick}>
//     <svg width="22" height="89" xmlns="http://www.w3.org/2000/svg">
//       <path d="M2 1l18 43.5L2 88" stroke="#FFF" strokeWidth="4" fill="none" fillRule="evenodd"/>
//     </svg>
//   </CarouselControlWrapper>
// )
const Container = styled.div`
  margin: auto;
  width: 100%;
  max-width: 930px;
  height: 35vw;
  max-height: 320px;
  & > .slider {
    height: 100% !important;
  }
`
const CarouselSlide = styled.div`
  // width: 100vw;
`

interface CarouselProps {
  slides: ImageLink[]
  mainCinemaId: CinemaId
  onSlideClick: (slide: ImageLink, idx: number) => void
}

export const MdsCarousel: React.SFC<CarouselProps> = ({slides, mainCinemaId, onSlideClick}) => {
  return (
    <Container>
      <Carousel autoplay={true} autoplayInterval={4000} heightMode="current"
                swiping={true} wrapAround={true} withoutControls={true}>
        {slides.map( (slide, idx) => (
          <CarouselSlide key={slide.image.sizes[0].url}>
            <SmartLink mainCinemaId={mainCinemaId} url={slide.link}
              onClick={() => {onSlideClick(slide, idx)}}>
              <RImage mode='eager' image={slide.image} alt="Slide"
                sizes={[{viewport: 370, size: 370}, {viewport: 500, size: 500}, {viewport: 700, size: 700}]}
                maxSize={930} />
            </SmartLink>
          </CarouselSlide>
        ))}
      </Carousel>
    </Container>
  )
}
