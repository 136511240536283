import React from 'react'
import { Link } from 'gatsby'

import { Movie, Times, Show, Shows, Days, Movies, MESSAGE_MOVIE_SLUGS } from '../model/model'
import styled from 'styled-components'
import { Column, Routing, SSR_Item, ItemTagHighlight } from './utils'
import { List, Item } from './utils'
import { Moment } from 'moment'
import RImage from './image';


const MovieShows = styled(Column)`
  height: fit-content;
`

const Film = styled(Column)`
  flex-wrap: wrap;
  // background-color: #222;
  // margin: 0 0 2rem 0;
  // padding: 5px;
  @media(min-width: 700px) {
    background-color: unset;
    margin: 0 0 0.2rem 0;
    &:hover {
      background-color: #222;
    }
  }
`

const NoShowMessage = styled.div`
text-transform: uppercase;
text-align: center;
margin: 0.5rem;
padding: 0.5rem;
border: 1px solid #FFF;
`

// const Backdrop = styled(RImage)<{cover: boolean}>`
// display: flex;
// object-fit: ${props => props.cover ? 'cover' : 'contain'};
// color: transparent;
// border: 5px solid #222;
// @media(min-width: 700px) {
//   display: none;
// }
// `

const BackdropFallback = styled.div`
  display: flex;
  width: 100%;
  height: calc((100vw - 30px) / 1.70);
  background-color: #333;
  border: 5px solid #222;
  @media(min-width: 700px) {
    display: none;
  }
`

const FilmTitle = styled.div`
  display: inline-flex;
  width: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
  flex-wrap: wrap;
`

const TitleWord = styled.span`
  flex-shrink: 0;
  margin-right: 5px;
  font-size: 1.1rem;
  color: #FFFFFF;
  text-transform: uppercase;
`

const VMWarning = styled.span`
  color: red;
  font-size: 0.85rem;
  line-height: 1.1rem;
  align-self: flex-end;
  margin-bottom: 1px;
  margin-right: 8px;
`

const Notice = styled.span`
  color: ${props => props.theme.colors.highlight };
  border: 1px solid ${props => props.theme.colors.highlight };
  padding: 0 2px;
  font-size: 0.7rem;
  line-height: 0.7rem;
  margin-bottom: 4px;
  margin-right: 8px;
  align-self: flex-end;
`

const Duration = styled.span `
  color: ${props => props.theme.colors.attenuate};
  font-size: 0.85rem;
  line-height: 1.1rem;
  align-self: flex-end;
  margin-bottom: 1px;
  margin-right: 8px;
  @media(min-width: 700px) {
    display: none;
  }
`

interface MovieShowListProps {
  now: Moment
  movies: Movie[]
  skipMoviesNotShowingToday: boolean
  getShows: (m: Movie) => Show[]
  noShowsMessage: string
  highlight: Movie | null
  onHighlight: (movie: Movie) => void
  // returns whether to follow the link
  onSelect: (movie: Movie) => boolean
}

class MovieShowList extends React.Component<MovieShowListProps, {isClient: boolean}> {
  constructor(props) {
    super(props)
    this.state = {isClient: false}
  }

  componentDidMount() {
    this.setState({isClient: true})
  }

  render() {
    const { now, movies, skipMoviesNotShowingToday, getShows, noShowsMessage, onHighlight, onSelect } = this.props
    const { isClient } = this.state
    const isAvailableShow = Shows.isAfter20Mins(now)
    const movieItems = movies.map(m => {
      const shows = getShows(m)

      return shows.length === 0 && skipMoviesNotShowingToday
        ? null
        : MESSAGE_MOVIE_SLUGS.includes(m.slug)
        ? <NoShowMessage key='no-show-message'>{m.title}</NoShowMessage>
        : (
          <Film key={m.id} onMouseEnter={(e) => {onHighlight(m)}}>
            <Link to={Routing.toMoviePage(m)} onClick={(e) => {
              if (!onSelect(m)) {
                e.preventDefault()
              }
            }}>
              {/* <Backdrop mode={isClient ? 'lazy' : 'stub'} alt={'Sfondo film ' + m.title}
                image={m.picture != null ? m.picture : m.poster}
                cover={m.picture != null ? true : false}
                sizes={[{viewport: 350, size: 350}, {viewport: 700, size: 700}]}
                maxSize={720} width="100%" height='calc((100vw - 30px) / 1.70)'/> */}
              <FilmTitle>
                {/* Hack: This was done because titles longer than one line were causing
                    the duration to end up on another line */}
                { m.title.split(' ')
                  .map ((word, i) => <TitleWord key={i}>{word}</TitleWord>)
                }
                { isClient
                  ? <Duration>{m.duration}'</Duration>
                  : null
                }
                { isClient
                  ? m.isVM18
                    ? <VMWarning>VM18</VMWarning>
                    : m.isVM14
                      ? <VMWarning>VM14</VMWarning>
                      : null
                  : null
                }
                { isClient
                  ? m.event != null && m.event.type === '2€'
                    ? <Notice>2 €uro</Notice>
                    : Movies.eventDays(m).length === 1
                      ? <Notice>SOLO OGGI</Notice>
                      : Movies.eventDays(m).length > 1
                        ? <Notice>EVENTO</Notice>
                        : null
                  : null
                }
              </FilmTitle>
              {
                shows.length === 0
                ? <div>-</div>
                : <List wrap='wrap'>
                  {
                    shows.map (show => (
                      // This horrible hack is due to styled components not playing well with SSR
                      // https://github.com/gatsbyjs/gatsby/issues/5993
                      // Also note https://github.com/facebook/react/issues/11128
                      // ...that was 1.5d I will never get back..
                      isClient
                      ? <Item key={Days.toString(show.day) + '@' + Times.toString(show.time)} state={ isAvailableShow(show) ? 'normal' : 'disabled'}>
                          { Times.toString(show.time) }
                          { show.tags
                          ? <ItemTagHighlight state={ isAvailableShow(show) ? 'normal' : 'disabled'}>{show.tags[0]}</ItemTagHighlight>
                          : null
                          }
                        </Item>
                      : <SSR_Item key={'SSR' + Days.toString(show.day) + '@' + Times.toString(show.time)}>
                          {Times.toString(show.time)}
                        </SSR_Item>
                    ))
                  }
                  </List>
              }
            </Link>
          </Film>
          )
      })

    return (
      <MovieShows>
      { movieItems.length === 0
        ? <NoShowMessage key='no-show-message'>{noShowsMessage}</NoShowMessage>
        : movieItems
      }
      </MovieShows>
    )
  }
}

export default MovieShowList

