import React from 'react'
import styled from 'styled-components';
import { useLockBodyScroll } from './utils';

const DrawerOverlay = styled.div<{isOpen: boolean}>`
  display: block;
  position: fixed;
  top: 0;
  right: ${props => props.isOpen ? 0 : '-100vw'};
  width: 100vw;
  height: 100vh;
  z-index: 5;
  transition: right 0.2s cubic-bezier(0.43, 0.89, 0.39, 1.02);
`

const DrawerContent = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 1rem;
  width: 90%;
  height: 100vh;
  background-color: rgba(18, 18, 18, 0.97);
  border-left: 5px solid ${props => props.theme.colors.highlight};
  overflow: hidden;
`

export interface DrawerProps {
  isOpen: boolean
  onClick: () => void
}
export const Drawer: React.SFC<DrawerProps> = ({isOpen, children, onClick}) => {
  useLockBodyScroll(isOpen)
  return (
    <DrawerOverlay isOpen={isOpen} onClick={onClick}>
      <DrawerContent>
        {children}
      </DrawerContent>
    </DrawerOverlay>
  )
}